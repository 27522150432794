

@import "~react-image-gallery/styles/css/image-gallery.css";

*{
  margin: 0;
  padding: 0;
  font-family: system-ui;
  padding-left: 0;
}

body{
  background: url(https://i.pinimg.com/originals/ac/e0/92/ace092d29db06daa4a7f5ae90b21cf11.jpg);
  background-size: cover;
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg{
  height: 58px;
    width: 70px;
}

.product-page{
  display: flex;
  justify-content: center;
  margin: 100px 0 30px;
  flex-wrap: wrap;
}

.product-page-infos{
  width: 500px;
  margin: 20px;
  padding: 20px 0;
}
.product-page-img{
  width: 450px;
  margin: 15px;
  padding: 40px 0;
}
.product-page-infos h3{
  font-weight: 400;
  font-size: 28px;
  color: white;
}
.product-page-infos h6{
  color: #ffc107;
  font-size: 15px;
  padding:3px 0;
  font-size: 11px;
}
.product-page-infos h2 {
  color: #ffc107;
  font-weight: 800;
  font-size: 32px;
}
.product-page-infos p {
  color: white;
  text-align: justify;
  font-size: 17px;
  padding:8px 0 15px;
  
}
.product-page .link-call{
  background-color: #25d366;
  padding: 11px 57px;

}
.product-page-infos a{
  padding: 15px 64px;
  background-color: #F89302;
  font-weight: 600;
  font-size: 13px;
  text-decoration: none;
  color: white;
  border: none;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
 
}
.product-page-infos button:hover{
  background-color: #eff36c;
  transition: 0.9s;
  -webkit-transition: 0.9s;
  -moz-transition: 0.9s;
  -ms-transition: 0.9s;
  -o-transition: 0.9s;
  cursor: pointer;
}

.product-page-title{
  padding: 25px;
  text-align: center;
}
.product-page-title h2{
 font-weight: 400;
}


 .product-page-desc{
   margin:4% 7%;
 }


 button[type="submit"]{

  background-color: #F89302;
    border-color: #F89302;
    padding: 6px 50px;
 }

 .link-back :hover{
   color: #F89302;
   text-decoration:underline;
 }

 [type=button]:not(:disabled){
   color: white;
   font-size: 12px;
 }
 .css-ahj2mt-MuiTypography-root{
  font-size: 2rem !important;
  color: white;
 }

